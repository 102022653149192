import React, {useEffect, useState} from "react";

import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import ModalFooter from "components/ui/modal/components/ModalFooter";
import MultiSelect from "components/ui/forms/components/fields/MultiSelect";
import {SubmitHandler, useForm, useWatch} from "react-hook-form";
import {useMainSnackBar} from "hooks/useMainSnackBar";
import {useAtom} from "jotai";
import {useApiAuth} from "services/api/useApiAuth";
import {useDate} from "hooks/useDate";
import {useQuarter} from "hooks/useQuarter";
import {allTheFacilitiesData, allTheMarketsData} from "services/jotai/requests/requests";
import {useCallsignConverter} from "hooks/useCallsignConverter";
import {cachedRequests} from "services/api/cachedRequests";
import urlPaths from "services/api/urlPaths";
import {NavLink as Link, useNavigate} from "react-router-dom";
import {Button, Typography} from "@mui/material";
import {userInfoAtom} from "services/jotai/auth/msauth";
import {Brand} from "../table/components/cell/Brand";
import {bulkUploadCsvIds} from "services/jotai/political/political";
import {CollapseTable} from "../table/collapseTable";
import {v4 as uuidv4} from "uuid";
import {settings} from "services/jotai/settings/settings";
import TextField from "./components/fields/TextField";
import CheckBox from "./components/fields/Checkbox";
import {useAtomWithQuery} from "../../../hooks/useAtomWithQuery";


interface IFormInputs {
  Markets: any | string | string[],
  Callsigns: string[],
  PdfFile: any,
  OrderType: "",
  OrderSearch: "",
  // OrderMatches: [],
  OrderSearchButton: boolean,
  OrderShowLatest: boolean,
  OrderYear: string,
  OrderQuarter: string,
  Matches: any[],
  OrderDetails: any,
  File: any,
  Files: any[],
  FileDetails: any[],
  Type: string,
  OfficeType: string,
  Office: string,
  Years: number | null,
  Quarters: any,
  CandidateOrIssueName: string,
  CustomFolder: string,
  CustomFolders: string[],
  FilePath: string[],
  ExistingFolders: string,
  Validates: boolean
}

interface Market {
  name: string;
  id: string | number;
}

interface MarketOutput {
  label: string;
  id: string | number;
  facility_id?: string | number;
}

const getMarkets = (allMarketsData: Market[]): MarketOutput[] => {
  const allMarkets: Set<MarketOutput> = new Set();

  allMarketsData?.forEach((market: Market) => {
    allMarkets.add({label: market.name, id: market.name});
  });

  return Array.from(allMarkets);
};
const getCallsigns = (selectedMarkets: any, tsMarkets: any) => {
  const allCallsigns: Set<any> = new Set([]);
  if (tsMarkets && selectedMarkets && selectedMarkets.length > 0) {
    if (typeof selectedMarkets === "string") {
      tsMarkets?.forEach((tsMarket: any) => {
        if (tsMarket["name"].toLowerCase() === selectedMarkets.toLowerCase()) {
          tsMarket.brands.forEach((brands: any) => {
            allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
          });
        }
      });
      return Array.from(allCallsigns);
    } else {
      tsMarkets?.forEach((tsMarket: any) => {
        selectedMarkets.forEach((selectedMarket: any) => {
          if (tsMarket["name"].toLowerCase() === selectedMarket.toLowerCase()) {
            tsMarket.brands.forEach((brands: any) => {
              allCallsigns.add({label: brands["callsign"], id: brands["callsign"], facility_id: brands["facility_id"]});
            });
          }
        });
      });
      return Array.from(allCallsigns);
    }
  } else {
    return [];
  }
};

export default function FccPoliticalForcedMatching(props: any) {
  const
    {requestAtom} = useAtomWithQuery(),
    {makeRequest} = useApiAuth(),
    [allMarketsData] = useAtom(allTheMarketsData),
    [globalSettings] = useAtom<any>(settings),
    [userInfo] = useAtom<{
      firstName: string,
      lastName: string,
      usename: string,
      isMsUser: boolean
    }[] | any>(userInfoAtom),
    {
      getCurrentQuarter,
      getArrayOfQuarters
    } = useQuarter(),
    navigate = useNavigate(),
    [bulkCsvIds] = useAtom(bulkUploadCsvIds),
    {
      getCurrentYear,
      getArrayOfYears,
      convertDatePretty
    } = useDate(),
    {cachedMarketsData} = cachedRequests(),
    {getCallsign} = useCallsignConverter(),
    [allFacilitiesData]: any = useAtom(allTheFacilitiesData),
    [resetPagination, setResetPagination] = useState(0),
    [submitted, setSubmitted] = useState(false),
    {makePoliticalFileUploadRequest} = useApiAuth(),
    {register, resetField, handleSubmit, control, setValue: parentSetValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        OrderType: "",
        OrderSearch: "",
        // OrderMatches: [],
        OrderSearchButton: false,
        OrderShowLatest: true,
        OrderYear: "",
        OrderQuarter: "",
        Validates: false,
        // Order: {},
        Markets: [],
        Callsigns: [],
        // Matches: [],
        File: {},
        Files: [],
        Years: getCurrentYear(),
        Quarters: `Q${getCurrentQuarter()}`,
        ExistingFolders: "Existing FCC Folders"
      },
    }),
    year: any = useWatch({control, name: "Years"}),
    matches = useWatch<any>({control, name: "Matches"}),
    files = useWatch<any>({control, name: "Files"}),
    market = useWatch({control, name: "Markets"}),
    callsigns = useWatch({control, name: "Callsigns"}),
    [marketCallsigns, setMarketCallsigns] = useState([]),
    [marketNames, setMarketNames] = useState<any>([]),
    yearOptions = getArrayOfYears(),
    {setMainSnackBar} = useMainSnackBar(),
    [newCallsigns, setNewCallsigns] = useState<any>([]),
    passesValidation = (data: any) => {
      if (Object.keys(data).length > 0) {
        const allValid = Object.keys(data).every((fileId: any) => {
          console.log("validation", data[fileId].Validates);
          return data[fileId].Validates === true;
        });
        return allValid;
      } else {
        return false;
      }
    },
    resolver = async (data: any) => {
      console.log("resolver data", data.matches, matches);
      const allValid = passesValidation(data.matches);
      console.log("validation resolver", allValid);

      if (allValid) {
        const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

        console.log("resolver submit data", allValid, data.matches);

        // const batchId = uuidv4().toString().replace(/-/gi, "");
        setSubmitted(true);

        let matchesSubmitted = 0;

        try {
          await Object.keys(data.matches).map(async (facilityId: any) => {
            let index = 0;

            console.log("facilityId", data.matches[facilityId].facility_id);

            // Add document to wo orders possiblematches array
            await makeRequest("POST", urlPaths.POLITICAL_MATCHES_ADD, null, {
              "csv_id": data.matches[facilityId].OrderDetails.csv_id,
              "file_id": data.matches[facilityId].FileDetails.file_id,
              "username": userInfo.username,
              "facility_id": data.matches[facilityId].facility_id,
              "callsign": data.matches[facilityId].callsign,
              "market_name": data.matches[facilityId].market,
              "name": data.matches[facilityId].FileDetails.name,
              "campaign_name": data.matches[facilityId].FileDetails.candidate_campaign_name,
              "year": String(data.matches[facilityId].OrderYear),
              "quarter": Number(data.matches[facilityId].OrderQuarter.replace("Q", "")),
              "date_based_quarter": Number(data.matches[facilityId].OrderQuarter.replace("Q", "")),
              "path": data.matches[facilityId].FileDetails.path
            }).then(async (response: any) => {

              // Add matched data to the fcc file

              if (response && response.success) {
                await makeRequest("POST", urlPaths.POLITICAL_MATCHES_SET, null, {
                  "csv_id": data.matches[facilityId].OrderDetails.csv_id,
                  "file_id": data.matches[facilityId].FileDetails.file_id,
                  "username": userInfo.username,
                  "method": "matching",
                  "form": "forced"
                }).then(async (resp: any) => {
                  if (response.success && resp.success) {
                    index++;
                    matchesSubmitted++;
                    await setMainSnackBar("success", `Document and order for ${data.matches[facilityId].callsign} successfully matched!`, "success");
                  }
                }).catch(async (error: any) => {
                  await setMainSnackBar("error", "Oh no, there was an error matching the FCC file!", "error");
                  // setMatchingData({count: 0, items: []});
                  console.log("error", error);
                });
                // setMatchingData(response ? response : {count: 0, items: []});
                // setSearching({count: searching.count + 1, searching: false, term: null});
              }
            }).catch(async (error: any) => {
              await setMainSnackBar("error", "Oh no, there was an error matching the WideOrbit order!", "error");
              // setMatchingData({count: 0, items: []});
              console.log("error", error);
            });

          });
          await sleep(4000);
          await setMainSnackBar("success", `${matchesSubmitted} new documents successfully matched!`, "success");
          // await navigate(`/political-candidates-and-issues/queue/${batchId}`);
        } catch (e: any) {
          setMainSnackBar("error", `Error matching documents: (${e})`, "error");
          console.log("error", e);
        }
      } else {
        if (Object.keys(data.matches).length >= 1) {
          await setMainSnackBar("error", "Oh no, it looks like orders and files are missing. Please double check your selections before submitting again!", "error");
        } else {
          await setMainSnackBar("error", "Oh no, it looks like an order and file selection are missing. Please double check your selections before submitting again!", "error");
        }
      }
      return true;
    },
    onSubmit: SubmitHandler<IFormInputs> = (data: any) => resolver({data: data, matches: matches}),
    [allBrandsData, setAllBrandsData] = useState<any>([]),
    getCsvData = () => {
      if (bulkCsvIds && bulkCsvIds.length >= 0) {
        let ids;
        if (bulkCsvIds.length >= 1) {
          ids = bulkCsvIds.map((item: any, index: number) => `csv_ids[${index}]=${encodeURIComponent(item)}`).join("&");
        } else {
          ids = bulkCsvIds.map((item: any, index: number) => `csv_ids[${index}]=${encodeURIComponent(item)}`);
        }
        return makeRequest("GET", `${urlPaths.POLITICAL_MATCHES_BY_IDS}?${ids}`, null, null).then(async (response: any) => {
          if (response) {
            // await setAllCsvData(response.items);
            return response.items;
          }
        }).catch((error: any) => {
          return error;
        });
      }
      return null;
    };

  useEffect(() => {
    setResetPagination(resetPagination + 1);
  }, []);

  // Populate data once the API returns the rest markets data
  useEffect(() => {
    cachedMarketsData();
  }, []);

  // Update files data when new callsigns are selected
  useEffect(() => {
    if (allBrandsData) {
      const data: any = {};
      allBrandsData.map((item: any) => {
        data[item.facility_id] = {
          OrderType: "",
          OrderSearch: "",
          // OrderMatches: [],
          OrderSearchButton: false,
          OrderShowLatest: true,
          OrderYear: getCurrentYear(),
          OrderQuarter: `Q${getCurrentQuarter()}`,
          OrderDetails: {},
          // file_type: "",
          // file_search: "",
          // file_matches: [],
          FileDetails: {},
          Validates: false,
          ...item
        };
      });

      parentSetValue("Matches", data);
    }
  }, [allBrandsData]);

  // Set the market names inside the select
  useEffect(() => {
    if (allMarketsData) {
      const allTsmMarkets: MarketOutput[] = getMarkets(allMarketsData);
      setMarketNames(allTsmMarkets);
    }
  }, [allMarketsData]);

  // Wipe callsign selections and repopulate the select options with new callsigns each time the markets dropdown changes
  useEffect(() => {
    parentSetValue("Callsigns", []);
    const allCallSigns: any = getCallsigns(market, allMarketsData);
    setMarketCallsigns(allCallSigns);
  }, [market]);

  useEffect(() => {
    setNewCallsigns(callsigns);
    const brandData: any = [];
    callsigns.map((callsign: any) => {
      if (allFacilitiesData) {
        brandData.push(
          allFacilitiesData[callsign]
        );
      }
    });
    setAllBrandsData(brandData);
    console.log("allBrandData", brandData);
  }, [callsigns]);

  useEffect(() => {
    if (props.submit) {
      console.log("trying to submit", props.submit);
      trigger().then((isValid) => {
        if (isValid) {
          handleSubmit(onSubmit)(); // Submits the form if all validations pass
        }
      });
    }
  }, [props.submit]);

  useEffect(() => {
    let count = 0;
    if (files && Object.keys(files)?.length > 0) {
      Object.keys(files)?.forEach((facility: any) => {
        if (files[facility].files) {
          count += files[facility].files?.length;
        }
      });
      // setFileCount(count);
    }
    console.log("files", files);
  }, [files]);

  const SelectAnOrder = (p: any) => {
    const {register, resetField, handleSubmit, control, setValue, reset, trigger} = useForm<IFormInputs>({
      defaultValues: {
        OrderShowLatest: matches[p.props.facility_id]?.OrderShowLatest,
        OrderYear: matches[p.props.facility_id]?.OrderYear,
        OrderQuarter: matches[p.props.facility_id]?.OrderQuarter,
        OrderSearchButton: matches[p.props.facility_id]?.OrderSearchButton,
        OrderType: matches[p.props.facility_id]?.OrderType,
        OrderSearch: matches[p.props.facility_id]?.OrderSearch,
          // OrderMatches: matches[p.props.facility_id]?.OrderMatches,
        OrderDetails: matches[p.props.facility_id]?.OrderDetails.label ? matches[p.props.facility_id]?.OrderDetails.label : "",
        FileDetails: matches[p.props.facility_id]?.FileDetails.label ? matches[p.props.facility_id]?.FileDetails.label : "",
        Validates: matches[p.props.facility_id]?.Validates
      },
    }),
      order_details: any = useWatch({control, name: "OrderDetails"}),
      order_year = useWatch({control, name: "OrderYear"}),
      order_quarter = useWatch({control, name: "OrderQuarter"}),
      order_type = useWatch({control, name: "OrderType"}),
      order_search = useWatch({control, name: "OrderSearch"}),
      order_search_button = useWatch({control, name: "OrderSearchButton"}),
      order_latest = useWatch({control, name: "OrderShowLatest"}),
      file_details: any = useWatch({control, name: "FileDetails"}),
      [errorState, setErrorState] = useState(null),
      // WideOrbit Orders Data
      wideOrbitOrdersAtom = requestAtom("matchingWoReq", urlPaths.POLITICAL_MATCHES, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        year: !order_latest ? order_year : undefined,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        market: [p.props.market], /*market,*/
        facility_id: [p.props.facility_id],
        quarter: !order_latest ? order_quarter : undefined,
        showLatest: order_latest ? order_latest : undefined,
        // matched: undefined,
        search: order_search && order_type?.toString() !== "Most Recent" ? order_search : undefined,
      }, setErrorState),
      [wideOrbitState, setWideOrbitState] = useState<any>(() => wideOrbitOrdersAtom),
      [{data: woData, isPending: woPending, isError: woError}] = useAtom<AtomQuery>(wideOrbitState),
      // Fcc Files Data
      fccFilesAtom = requestAtom("matchingFccReq", urlPaths.POLITICAL_ISSUES, {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        compliant: order_details ? undefined : "2024",
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        market: [p.props.market], /*market,*/
        facility_id: p.props.facility_id,
        startDate: order_details ? order_details.date_created : undefined,
        endDate: order_details ? order_details.flight_end : undefined,
      }, setErrorState),
      [fccFilesState, setFccFilesState] = useState<any>(() => fccFilesAtom),
      [{data: fccData, isPending: fccPending, isError: fccError}]: any = useAtom<AtomQuery>(fccFilesState),
      [fccDataOptions, setFccDataOptions] = useState<any>([]);

    // Update details when applicable
    useEffect(() => {
      matches[p.props.facility_id].OrderYear = order_year;
      matches[p.props.facility_id].OrderQuarter = order_quarter;
      matches[p.props.facility_id].OrderType = order_type;
      matches[p.props.facility_id].OrderShowLatest = order_latest;
      setWideOrbitState(wideOrbitOrdersAtom); // Get the latest WideOrbit orders
    }, [order_year, order_quarter, order_type, order_latest]);

    // When the order search button is clicked
    useEffect(() => {
      matches[p.props.facility_id].OrderSearchButton = order_search_button;
      setFccDataOptions([]);
      setWideOrbitState(wideOrbitOrdersAtom);
    }, [order_search_button]);

    // When order search changes
    useEffect(() => {
      matches[p.props.facility_id].OrderSearch = order_search;
    }, [order_search]);


    // Add fcc files each time the fcc data changes
    useEffect(() => {
      const tempData: any = [];
      fccData?.items?.map((item: any) => {
        item?.files?.map((file: any) => {
          delete item.files;
          tempData.push({...item, ...file});
        });
      });
      setFccDataOptions(tempData);
    }, [fccData]);

    // Add wide orbit order details
    useEffect(() => {
      if (order_details?.advertiser) {
        matches[p.props.facility_id].OrderDetails = order_details;
      }
      setFccFilesState(fccFilesAtom);
    }, [order_details]);

    // Add fcc file details
    useEffect(() => {
      if (file_details?.name) {
        matches[p.props.facility_id].FileDetails = file_details;
      }
    }, [file_details]);

    // Validation
    useEffect(() => {
      if (order_details && order_details?.advertiser && file_details && file_details.name) {
        matches[p.props.facility_id].Validates = true;
      } else {
        matches[p.props.facility_id].Validates = false;
      }
    }, [order_details, file_details]);

    return <Stack direction={"column"} spacing={2}>
      <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
        <MultiSelect {...{
          name: "OrderType",
          label: "Type",
          multiSelect: false,
          validationMessage: "Select a type",
          options: ["Most Recent", "Order ID", "Search Term"],
          required: true,
          control: control
        }}  />
        <CheckBox {...{
          name: "OrderShowLatest",
          label: "Past 30 Days?",
          required: false,
          control: control
        }} />

        {!order_latest ? <MultiSelect {...{
          name: "OrderYear",
          label: "Year",
          multiSelect: false,
          validationMessage: "Select a year",
          options: getArrayOfYears(),
          required: true,
          control: control
        }}  /> : <></>}

        {!order_latest ? <MultiSelect {...{
          name: "OrderQuarter",
          label: "Quarter",
          multiSelect: false,
          validationMessage: "Select a year",
          options: getArrayOfQuarters(Number(order_year)),
          required: true,
          control: control
        }}  /> : <></>}

        {order_type && order_type !== "Most Recent" ? <TextField {...{
          name: "OrderSearch",
          label: `Search`,
          validationMessage: `Please enter a search term`,
          required: false,
          spacesAllowed: true,
          specialCharactersAllowed: true,
          control: control
        }} /> : <></>}
        {order_type && order_type !== "Most Recent" ? <Button variant="contained" disableElevation size={"small"}
          onClick={() => {
            setValue("OrderSearchButton", true);
          }}
          sx={{
            backgroundColor: "rgb(162 162 162)",
            color: "white"
          }}>Search</Button> : <></>}
      </Stack>
      {String(order_search).length > 0 && order_search_button === true || order_type && order_type === "Most Recent" ?
        <Stack direction={"column"} spacing={2}>
          <MultiSelect {...{
            name: "OrderDetails",
            label: "Select an order",
            multiSelect: false,
            validationMessage: "Select an order",
            options: woData?.items.map((item: any) => {
              return {
                document: {
                  ...item,
                  id: uuidv4(),
                  label: <Stack>
                    <Typography sx={{fontSize: "10px"}}>Advertiser: {item.advertiser}</Typography>
                    {item.order_number ?
                      <Typography sx={{fontSize: "10px"}}>Order#: {item.order_number}</Typography> : <></>}
                    {item.estimate ? <Typography sx={{fontSize: "10px"}}>Est#: {item.estimate}</Typography> : <></>}
                    {item.flight_start ?
                      <Typography
                        sx={{fontSize: "10px"}}>Flight: {convertDatePretty(item.flight_start)} - {convertDatePretty(item.flight_end)}</Typography> : <></>}
                  </Stack>
                }
              };
            }),
            height: "65px",
            required: true,
            control: control
          }}  />
        </Stack> : <></>}
      {String(order_search).length > 0 && order_search_button === true || order_type && order_type === "Most Recent" ?
        <Stack direction={"column"} spacing={2}>
          <MultiSelect {...{
            name: "FileDetails",
            label: "Select a file",
            multiSelect: false,
            validationMessage: "Select an order",
            options: fccDataOptions?.map((item: any) => {
              return {
                document: {
                  ...item,
                  id: uuidv4(),
                  label: <Stack>
                    {item.candidate_campaign_name ?
                      <Typography
                        sx={{fontSize: "10px"}}>Candidate: {item.candidate_campaign_name}</Typography> : <></>}
                    {item.path ? <Typography sx={{fontSize: "10px"}}>Path: {item.path}</Typography> : <></>}
                    {item.name ? <Typography sx={{fontSize: "10px"}}>Name: {item.name}</Typography> : <></>}
                    {item.upload_date ? <Typography
                      sx={{fontSize: "10px"}}>Uploaded: {convertDatePretty(item.upload_date)}</Typography> : <></>}
                  </Stack>
                }
              };
            }),
            height: "80px",
            required: true,
            control: control
          }}  />
        </Stack> : <></>}
    </Stack>;
  };


  const collapse_columns = [{
    field: "market",
    id: "market",
    headerName: "Market",
    flex: 1,
    renderCell: (params: { market: string, facility_id: string }) => {
      return (<Link to={`/market/${params?.market}/`}>{params?.market}</Link>);
    }
  }, {
    field: "brand",
    id: "brand",
    headerName: "Brand",
    maxWidth: "60px",
    align: "left",
    sortable: false,
    editable: false,
    disableColumnMenu: true,
    disableColumnFilter: true,
    disableColumnSelector: true,
    renderCell: (params: { callsign: string, facility_id: string, market: string, service: string }) =>
      <Brand {...{
        callsign: params?.callsign,
        facility_id: params?.facility_id,
        service: params?.service
      }} />
  }, {
    field: "callsign",
    id: "callsign",
    headerName: "Call Sign",
    maxWidth: "120px",
    renderCell: (params: { callsign: string, facility_id: string, market: string, service: string }) =>
      <Link to={`/facility/${params.facility_id}/`}>{params.callsign}</Link>
  }, {
    field: "validates",
    id: "validates",
    headerName: "Status",
    renderCell: (params: { facility_id: string, Validates: string }) => {
      return matches[params.facility_id]?.Validates ? "Ready To Submit" : "Select an order and file";
    }
  }];

  return (
    <form onSubmit={handleSubmit(onSubmit)} name={"political_candidates_and_issues_forced_matching"}>
      <Grid container rowSpacing={3} columnSpacing={{xs: 1, sm: 2, md: 2}} sx={{padding: "20px"}}>
        <Grid item xs={12}>
          <Stack direction={!props?.mobile ? "row" : "column"} spacing={2}>
            <MultiSelect {...{
              name: "Years",
              label: "Year",
              multiSelect: false,
              validationMessage: "Select a year",
              options: yearOptions,
              required: true,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Markets",
              label: "Markets",
              multiSelect: true,
              validationMessage: "Select at least one market",
              options: marketNames && marketNames.length > 0 ? marketNames : [],
              disabled: false,
              required: false,
              handleChange: onSubmit,
              control: control
            }}  />
            <MultiSelect {...{
              name: "Callsigns",
              label: "Callsigns",
              multiSelect: true,
              validationMessage: "Select at least one callsign",
              options: marketCallsigns && marketCallsigns.length > 0 ? marketCallsigns : [],
              disabled: false,
              required: false,
              handleChange: onSubmit,
              control: control
            }}  />
          </Stack>
        </Grid>
        {allBrandsData?.length > 0 ? <Grid item xs={12}>
          <div style={{
            borderTop: "1px solid #e5e7eb"
          }}/>
          <p style={{
            fontWeight: "100",
            fontSize: "14px",
            marginTop: "14px",
            marginBottom: "14px"
          }}><strong>Document Matching:</strong></p>
          <CollapseTable {...{
            data: allBrandsData?.length > 0 ? allBrandsData : [], // tableData && tableData?.length > 0 ? tableData : [],
            columns: props.mobile ? collapse_columns : collapse_columns,
            pending: false,
            tableKey: "politicalMatchingTablev2",
            mobile: props.mobile,
            resetPagination: resetPagination,
            // tableSortBy: "order_number",
            // subTableColumns: columns,
            subTableComponent: SelectAnOrder,
            subTableKey: "possibleMatchesv2",
            // subTableSortBy: "score",
            loading: false,
            error: false,
            errorMessage: ""
          }} />
        </Grid> : <></>}
      </Grid>
      <ModalFooter {...{
        buttons: {
          left: [],
          right: [{
            variant: "contained",
            color: null,
            name: "Upload To FCC",
            type: "submit",
          }]
        },
        close: props.handleChange,
        submitButtonState: marketCallsigns.length === 0 && submitted !== false ? true : false
      }} />
    </form>
  );
}