import React from "react";
import {Controller} from "react-hook-form";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";

const MultiSelect = (props: any) => {
  const validationMessage = props.validationMessage ? props.validationMessage : "*Required";
  const multiSelect = props.multiSelect ? true : false;
  return (
    <Controller
      name={props.name}
      control={props.control}
      rules={{
        validate: {
          required: (value: []) => {
            if (props.required && value && value.length <= 0 || props.required && !value) {
              return validationMessage;
            }
          }
        }
      }}
      render={({field: {onChange, onBlur, value, name, ref}, fieldState: {error}}) => <FormControl fullWidth>
        <InputLabel shrink id={props.label} style={{color: error ? "red" : ""}}>{props.label}</InputLabel>
        <Select
          disabled={props.disabled ? props.disabled : null}
          error={!!error}
          MenuProps={{style: {height: "300px"}}}
          notched={true}
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          multiple={multiSelect}
          onBlur={onBlur}
          value={value}
          ref={ref}
          onChange={onChange}
          label={props.label}
          size={"small"}
          margin={"dense"}
          style={{
            height: props?.height ? props.height : "30px",
            fontSize: "12px"
          }}
          input={<OutlinedInput label={props.label}/>}
          renderValue={props?.options && props.options.length > 0 && props.options[0].document ? (selected: any) => {
            if (!selected) {
              return "";
            }
            if (Array.isArray(selected)) {
              console.log("selected", selected);
              return selected.map((val) => val.label ? val.label : val).join(", ");
            }
            return selected.label || selected;
          } : undefined}
        >
          {props && props.options && props.options.length > 0 ? props.options.map((option: any) => {
            if (option.id && !option.facility_id) {
              return (<MenuItem key={option.id} value={option.id}>{option.label}</MenuItem>);
            } else if (option.id && option.facility_id) {
              return (<MenuItem key={option.facility_id} value={option.facility_id}>{option.label}</MenuItem>);
            } else if (option && option.document && option.document.label) {
              return (
                <MenuItem key={option.document.id} value={option.document}>{option.document.label}</MenuItem>);
            } else {
              return (<MenuItem key={option} value={option}>{option}</MenuItem>);
            }
          }) : []}
        </Select>
        <FormHelperText style={{color: "red", display: error ? "block" : "none"}}>{validationMessage}</FormHelperText>
      </FormControl>}
    />
  );
};

export default MultiSelect;


// required: props.required,

// helperText={error ? error.message : null}

// style={{
//   // borderColor: "green",
//   // color: "green",
//   // backgroundColor: "red",
//   // paddingLeft: "10px",
//   height: "30px",
//     fontSize: "12px"
// }}

// <MultiSelectElement
//   name={props.name}
//   label={props.label}
//   options={props.options}
//   // margin={"dense"}
//   // validation={{ required: "Hmm interesting" }}
//   // size={"small"}
//   // InputProps={{
//   //   margin: "dense",
//   //   size: "small"
//   // }}
//   size={"small"}
//   margin={"dense"}
//   fullWidth={true}
//   showChips={true}
//   variant={"standard"}
//   disableUnderline={true}
//   showCheckbox={true}
//   preserveOrder={true}
//   menuMaxWidth={50}
//   menuMaxHeight={20}
//   // shrink={true}
//   // inputProps={{
//   //   fullWidth: true,
//   //   disabled: false,
//   //   // margin: "dense",
//   //   size: "small",
//   //   variant: "standard",
//   // }}
//   formLabelProps={{
//     shrink: true,
//   }}
//   // shrink={true}
//   // inputLabel={{shrink:true}}
//   // disableUnderline={"disableUnderline"}
//   // control={{
//   //   // menuMaxHeight: 20
//   // }}
//   inputProps={{
//     menuMaxHeight: "20px",
//     shrink:true,
//     textFieldProps: {
//       margin: "dense",
//       size: "small",
//       variant: "standard",
//       InputProps: {
//         disableUnderline: true
//       },
//       InputLabelProps: {
//         shrink: true
//       }
//     },
//     MenuProps: {
//       menuMaxHeight: "20px",
//       InputLabelProps: {
//         shrink: true
//       }
//     },
//   }}
//   MenuProps={{
//     // menuMaxHeight: "20px",
//     PaperProps: {
//       menuMaxHeight: 20,
//       inputLabelProps: {
//         shrink: true
//       },
//       formLabelProps: {
//         shrink: true,
//       },
//       style: {
//         maxHeight: 20
//       },
//       sx: {
//         "& .MuiButtonBase-root, .MuiMenuItem-root": {
//           // display: "grid",
//           // gap: "4px",
//           width: "50px",
//           // gridTemplateColumns: "repeat(3, 1fr)"
//         }
//       }
//     }
//   }}
//   formControlProps={{
//     // formLabelProps: {
//     //   shrink: true,
//     // },
//     // MenuProps: {
//     //   shrink:true
//     // },
//     // disableUnderline: true,
//     // InputProps: {
//     //   margin: "dense",
//     //   size: "small",
//     //   variant: "standard",
//     //   disableUnderline: true
//     // },
//     // InputLabelProps: {
//     //   shrink: true
//     // },
//     sx: {
//       "& .MuiInputBase-root": {
//         // borderColor: "green",
//         // color: "green",
//         // backgroundColor: "red",
//         border: "solid 1px",
//         borderRadius: "3px"
//       },
//       // "& .MuiInputBase-root": {
//       //   // height: "30px",
//       //   // width: "100%",
//       //   border: "solid 1px",
//       //   borderRadius: "3px"
//       // },
//       "& .MuiInput-root .MuiInput-input": {
//         paddingLeft: "10px",
//         marginBottom: "-2px"
//       }
//     }
//   }}
// />